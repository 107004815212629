import { mapGetters, mapActions, mapMutations } from "vuex"
import ThemisIssueFilters from "@/components/issue-filters"
import { getFootersForTable } from "@/utils/table"
import {
  DATA_EXPORT_STATUS,
  FIELD_TYPES,
  ISSUE_TYPES,
  ISSUE_SEARCH_STATUS,
  ISSUE_SEARCH_POLL_INTERVAL,
  TABLE_NAMES,
  SCREEN_ITEM_DISPLAY_PROPERTIES,
  DEFAULT_DATA_RETENTION_PERIODS,
  SCREEN_ITEM_TYPE
} from "@/constants"
import { FILTER } from "@/constants/bread-crumbs/filter"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"
import ThemisIssuesColumnsPreference from "@/components/issues-columns-preference"
import ThemisBulkImportCases from "@/components/bulk-import-cases"
import ThemisScreen from "@/components/screen"
import { convertDaysToDuration, checkObjectEqualityIgnoreOrder } from "@/utils"

export default {
  name      : "Issues",
  components: {
    ThemisIssueFilters,
    ThemisIssuesColumnsPreference,
    ThemisBulkImportCases,
    ThemisScreen
  },
  data() {
    return {
      showAddIssueDialog          : false,
      domainId                    : null,
      localIssuesFiltersPreference: {},
      localIssuesSortingPreference: null,
      screenItemsForComponent     : [],
      issueTypes                  : [{
        header: this.$t("1272")
      },
      {
        name : ISSUE_TYPES[0].name,
        value: ISSUE_TYPES[0].id
      }],
      allFilters                : new Array(),
      filterCriteria            : {},
      showIssueFilters          : false,
      issueAnonymisationStatuses: [
        { name: this.$t("632"), value: this.$CONSTANTS.DATA_RETENTION_STATUS.NOT_SCHEDULED },
        { name: this.$t("633"), value: this.$CONSTANTS.DATA_RETENTION_STATUS.SCHEDULED },
        { name: this.$t("634"), value: this.$CONSTANTS.DATA_RETENTION_STATUS.ANONYMISED }
      ],
      reportStatuses: [
        { name: this.$t("636"), value: this.$CONSTANTS.REPORT_STATUS.CLOSED },
        { name: this.$t("635"), value: this.$CONSTANTS.REPORT_STATUS.NEW }
      ],
      reportSources: [
        { name: this.$t("1162"), value: this.$CONSTANTS.REPORT_SOURCE.WEB },
        { name: this.$t("1163"), value: this.$CONSTANTS.REPORT_SOURCE.APP },
        { name: this.$t("1164"), value: this.$CONSTANTS.REPORT_SOURCE.PHONE }
      ],
      messageStatuses: [
        { name: this.$t("2067"), value: this.$CONSTANTS.MESSAGE_FILTER_STATUS.READ },
        { name: this.$t("2068"), value: this.$CONSTANTS.MESSAGE_FILTER_STATUS.UNREAD }
      ],
      initialResponseStatuses: [
        { name: this.$t("2070"), value: this.$CONSTANTS.INITIAL_RESPONSE_STATUS.RESPONDED },
        { name: this.$t("2071"), value: this.$CONSTANTS.INITIAL_RESPONSE_STATUS.NOT_RESPONDED }
      ],
      issueStatusCategories: [
        { name: this.$t("2126"), value: this.$CONSTANTS.ISSUE_STATUS_CATEGORY.NEW },
        { name: this.$t("2127"), value: this.$CONSTANTS.ISSUE_STATUS_CATEGORY.IN_PROGRESS },
        { name: this.$t("2128"), value: this.$CONSTANTS.ISSUE_STATUS_CATEGORY.DONE }
      ],
      showIssuesColumnsPreference : false,
      searchText                  : null,
      searchThroughAllIssueData   : false,
      issueSearchPollingInterval  : null,
      showBulkImportCasesDialog   : false,
      ignoreSearchFieldBlur       : false,
      isFilterNameDuplicate       : false,
      advancedFiltersCount        : 0,
      filterActionsVisible        : false,
      localIssuesColumnsPreference: null,
      isSavingExistingFilterAsNew : false,
      tableHeaderHeight           : 48
    }
  },
  beforeDestroy() {
    this.clearIssueSearchPollingInterval()
  },
  methods: {
    ...mapActions({
      addExport                  : "exports/addExport",
      updateUser                 : "users/updateUser",
      loadIssueSearch            : "issueSearch/loadIssueSearch",
      loadIssueSearchWithCriteria: "issueSearch/loadIssueSearchWithCriteria",
      loadIssuesFromIssueSearchId: "issueSearch/loadIssuesFromIssueSearchId",
      notify                     : "shared/notify",
      makeTransition             : "transitions/makeTransition",
      uploadCsvFilesToBulkImport : "bulkImport/uploadCsvFilesToBulkImport",
      addFilter                  : "filters/addFilter",
      updateFilter               : "filters/updateFilter"
    }),
    ...mapMutations({
      setProgressBarPromisesPending: "shared/setProgressBarPromisesPending",
      setFilterAddError            : "filters/setFilterAddError",
      setBreadcrumbs               : "shared/setBreadcrumbs",
      setPageTitle                 : "shared/setPageTitle"
    }),
    userHasAccessToTheDomain(domainId) {
      return this.allowedIssuesAndDomainsForIssueViewPolicy.domainIds.includes(domainId)
    },
    async addNewIssue() {
      this.showAddIssueDialog      = true
      let screenItems              = this.initialTransitionCase.screen
        ? [...this.initialTransitionCase.screen.screenItems].filter(screenItem =>
          screenItem.issueProperty?.key !== "domainId")
        : []
      screenItems                  = [{
        type         : SCREEN_ITEM_TYPE.ISSUE_PROPERTY,
        issueProperty: {
          key         : "domainId",
          mandatory   : true,
          sortingOrder: 0,
          field       : []
        }
      }, ...screenItems]
      this.screenItemsForComponent = screenItems
        .filter(screenItem => {
          if (screenItem.type === SCREEN_ITEM_TYPE.FORM
              && !this.issueFormTemplateIds.includes(screenItem.form.formTemplateId)) {
            return false
          }
          if (screenItem.type === SCREEN_ITEM_TYPE.FIELD && !this.issueFieldIds.includes(screenItem.field.fieldId)) {
            return false
          }
          return true
        })
        .sort((firstScreenItem, secondScreenItem) => firstScreenItem.sortingOrder - secondScreenItem.sortingOrder)
        .map(screenItem => {
          let issueFieldForScreen
          let issueForm                 = null
          const issueFormFieldForScreen = []
          if (screenItem && screenItem.type === SCREEN_ITEM_TYPE.FIELD) {
            const screenItemField = screenItem.field
            const field           = this.fieldsMap[screenItemField?.fieldId]
            const issueFieldId    = this.issueFields.find(
              issueField => issueField.fieldId === screenItemField?.fieldId)?.id
            if (field) {
              issueFieldForScreen = {
                fieldId             : field.id,
                issueFieldId        : issueFieldId,
                fieldType           : field.type,
                fieldWidget         : field.widget,
                fieldLabel          : screenItem.field?.mandatory ? `${field.label}*` : field.label,
                optionListId        : field.optionListId,
                isOptionListCascaded: this.isOptionListCascaded(field),
                issueFieldValues    : []
              }
            }
            issueFieldForScreen = {
              ...issueFieldForScreen,
              optionListItems: this.getOptionListItemsForAParticularIssueField(issueFieldForScreen),
              readOnly       : this.isReadOnlyIssueFields()
            }
          } else if (screenItem && screenItem.type === SCREEN_ITEM_TYPE.FORM) {
            const screenItemForm = screenItem.form
            issueForm            = this.formTemplates?.find(formTemplate =>
              formTemplate.id === screenItemForm?.formTemplateId)
            if (screenItemForm?.formFields?.length) {
              const sortedScreenFormFields = screenItemForm.formFields.sort((firstFormField, secondFormField) =>{
                return this.getFormTemplateConfigurationSortingMap[firstFormField.formTemplateConfigurationId] -
                  this.getFormTemplateConfigurationSortingMap[secondFormField.formTemplateConfigurationId]
              })
              for (const formField of sortedScreenFormFields) {
                const formTemplateConfiguration = this.formTemplateConfigurations
                  ?.find(formTemplateConfiguration =>
                    formTemplateConfiguration.id === formField.formTemplateConfigurationId)
                const field                     = this.fieldsMap[formTemplateConfiguration?.fieldId]
                if (field) {
                  issueFieldForScreen            = {
                    fieldId                    : field.id,
                    issueFieldId               : formTemplateConfiguration?.fieldId,
                    formTemplateId             : screenItemForm?.formTemplateId,
                    formTemplateConfigurationId: formField.formTemplateConfigurationId,
                    fieldType                  : field.type,
                    fieldWidget                : field.widget,
                    fieldLabel                 : formField.mandatory ? `${field.label}*` : field.label,
                    optionListId               : field.optionListId,
                    issueId                    : +this.$route.params.id,
                    isOptionListCascaded       : this.isOptionListCascaded(field)
                  }
                  issueFieldForScreen.issueForms = [{
                    formTemplateId: screenItemForm?.formTemplateId,
                    formFields    : [{
                      formTemplateConfigurationId: formField.formTemplateConfigurationId,
                      value                      : null
                    }]
                  }]
                }
                issueFieldForScreen = {
                  ...issueFieldForScreen,
                  mandatory      : formField.mandatory,
                  optionListItems: this.getOptionListItemsForAParticularIssueField(issueFieldForScreen),
                  readOnly       : this.isIssueFormFieldsReadOnly()
                }
                issueFormFieldForScreen.push(issueFieldForScreen)
              }
            }
          }
          switch (screenItem.type) {
            case SCREEN_ITEM_TYPE.ISSUE_PROPERTY:
              return {
                key      : screenItem.issueProperty?.key,
                mandatory: screenItem.issueProperty.mandatory,
                items    : this[SCREEN_ITEM_DISPLAY_PROPERTIES[screenItem.issueProperty.key]?.computedPropertyValue],
                field    : issueFieldForScreen,
                type     : screenItem.type
              }
            case SCREEN_ITEM_TYPE.FIELD:
              return {
                key      : screenItem.field?.fieldId,
                mandatory: screenItem.field?.mandatory,
                field    : issueFieldForScreen,
                form     : issueForm,
                type     : screenItem.type
              }
            case SCREEN_ITEM_TYPE.FORM:
              return {
                type      : screenItem.type,
                form      : issueForm,
                formFields: issueFormFieldForScreen
              }
          }
        })
    },
    isOptionListCascaded(issueField) {
      const filteredOptionListItems = this.optionListItems.filter(optionList =>
        optionList.optionListId === issueField.optionListId)
      return !!filteredOptionListItems.find(optionListItem => optionListItem.parentId)?.parentId
    },
    getOptionListItemsForAParticularIssueField(issueField) {
      if ([FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value]
        .includes(issueField?.fieldType)) {
        const sortedOptionListItems = this.optionListItems
          .filter(optionListItem => optionListItem.optionListId === issueField.optionListId)
          .sort((optionListItem1, optionListItem2) => optionListItem1.sortingOrder - optionListItem2.sortingOrder)
          .map(optionListItem => ({
            id      : optionListItem.id,
            name    : optionListItem.name,
            parentId: optionListItem.parentId
          }))

        const isMultiple = this.isFieldTypeMultipleOptionList(issueField)
        const options    = this.generateNestedOptionListItems(sortedOptionListItems, isMultiple)
        return options
      }
    },
    isFieldTypeMultipleOptionList(issueField) {
      return issueField.fieldType === FIELD_TYPES.MULTIPLE_OPTION_LIST.value
    },
    generateNestedOptionListItems(data, isMultiple) {
      const idToObject = {}
      const result     = []

      data.forEach(item => {
        idToObject[item.id] = { ...item, children: [] }
      })

      data.forEach(item => {
        const parent = idToObject[item.parentId]
        if (parent) {
          parent.disabled = !isMultiple
          parent.children.push(idToObject[item.id])
        } else {
          result.push(idToObject[item.id])
        }
      })
      return result
    },
    isReadOnlyIssueFields() {
      return false
    },
    isIssueFormFieldsReadOnly() {
      return false
    },
    updateUserPreferenceForRowsPerPage(itemsPerPage) {
      this.updateUser({ id: this.loggedInUser.id, issuesRowsPerPagePreference: itemsPerPage })
    },
    /**
     * utility to generate search criteria which can be directly queried on issues table or store
     * @param {*} filters - contains all the filters made by user on issues
     */
    generateSearchCriteria(filters) {
      this.filterCriteria         = {}
      let reportFormTemplateIndex = 0
      let issueFormTemplateIndex  = 0
      for (const filter of filters) {
        const entityName    = filter.key[0]
        const entityId      = filter.key[1]
        const updatedFilter = {}
        if (entityId === this.$CONSTANTS.ISSUE_SEARCH_FILTERS.FORM_TEMPLATE) {
          const fieldId                    = `report[0].formInstances[${reportFormTemplateIndex}].formInstanceFields[0].fieldId`
          const fieldValue                 = `report[0].formInstances[${reportFormTemplateIndex}].formInstanceFields[0].formInstanceFieldValues[0].value`
          const formTemplateIdKey          = `report[0].formInstances[${reportFormTemplateIndex}].formTemplateId`
          updatedFilter[formTemplateIdKey] = filter.key[2].formTemplateId
          updatedFilter[fieldId]           = filter.key[2].fieldId
          updatedFilter[fieldValue]        = [...filter.values]
          reportFormTemplateIndex++
        } else if (entityId === this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FORM) {
          const fieldId                    = `formInstances[${issueFormTemplateIndex}].formInstanceFields[0].fieldId`
          const fieldValue                 = `formInstances[${issueFormTemplateIndex}].formInstanceFields[0].formInstanceFieldValues[0].value`
          const formTemplateIdKey          = `formInstances[${issueFormTemplateIndex}].formTemplateId`
          updatedFilter[formTemplateIdKey] = filter.key[2].formTemplateId
          updatedFilter[fieldId]           = filter.key[2].fieldId
          updatedFilter[fieldValue]        = [...filter.values]
          issueFormTemplateIndex++
        } else if (entityId === this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD) {
          const issueFieldIndex = Object.keys(this.filterCriteria).filter(criteria =>
            criteria.indexOf(this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD) !== -1).length / 2

          const issueFieldId    = this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD.concat("[").concat(issueFieldIndex).concat("].issueFieldId")
          const issueFieldValue = this.$CONSTANTS.ISSUE_SEARCH_FILTERS.ISSUE_FIELD.concat("[").concat(issueFieldIndex).concat("].value")

          updatedFilter[issueFieldId]    = filter.key[2].issueFieldId
          updatedFilter[issueFieldValue] = [...filter.values]
        } else {
          switch (entityName) {
            case this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DATE_RANGE[0]:
              updatedFilter[entityId] = [filter.values[0].concat("T00:00:00"), filter.values[1].concat("T23:59:59")].join("to")
              break
            case this.$CONSTANTS.ISSUE_SEARCH_FILTERS.DUE_DATE[0]:
              if (filter.values[0].split("T").length === 2) {
                updatedFilter[entityId] = filter.values.join("to")
              } else {
                updatedFilter[entityId] = [
                  this.$moment(filter.values[0] + "T00:00:00").utc().toISOString(),
                  this.$moment(filter.values[1] + "T23:59:59").utc().toISOString()
                ].join("to")
              }
              break
            case this.$CONSTANTS.ISSUE_SEARCH_FILTERS.UPDATED_AT[0]:
              if (filter.values[0].split("T").length === 2) {
                updatedFilter[entityId] = filter.values.join("to")
              } else {
                updatedFilter[entityId] = [
                  this.$moment(filter.values[0] + "T00:00:00").utc().toISOString(),
                  this.$moment(filter.values[1] + "T23:59:59").utc().toISOString()
                ].join("to")
              }
              break
            default:
              updatedFilter[entityId] = [...filter.values]
              break
          }
        }
        this.filterCriteria = Object.assign({}, this.filterCriteria, updatedFilter)
      }
    },
    /**
     * This method converts the flattened json with dot notation into its expanded representation
     * Eg. {"report[0].channel": 2, "issueStatuses": [23, 34]}
     *      will be resolved to
     *  {
     *    "report": [{
     *      "channel": 2
     *    }],
     *    "issueStatuses": [23, 34]
     *  }
     * unflattenJson will point to the root of object(at starting it will be {}), tempObject will point inside array or array of object(it goes into deeper layer like inside array of object and inside that object)
     * We split the key of filterCriteria("report[0].channel") and checks whether it is an array("report[0]") or an object key("channel")
     * If it's an array we create a new array with empty object with index('0' for "report[0]") only if there is no same array name present in the same layer
     * If its an object key, we create an object key and assign the value to it("2") only if its last index of split result
     * At the last index of split result, in the end we make the tempObject point to the root of unflattenJson
     */
    generateUnflattenJson() {
      const arrayElementRegex = /^[a-zA-Z]+\[[0-9]+\]$/
      const unflattenJson     = {}
      let tempObject          = unflattenJson
      for (const fullyQualifiedEntityName of Object.keys(this.filterCriteria)) {
        fullyQualifiedEntityName.split(".").map((key, index, entityNames) => {
          let arrayIndex = -1
          if (index === entityNames.length - 1) {
            tempObject[key] = this.filterCriteria[fullyQualifiedEntityName]
          } else if (arrayElementRegex.test(key)) {
            const startArrayIndex = key.indexOf("[")
            const endArrayIndex   = key.indexOf("]")
            arrayIndex            = parseInt(key.substring(startArrayIndex + 1, endArrayIndex))
            key                   = key.substring(0, startArrayIndex)
            if (!tempObject[key]) {
              tempObject[key]             = []
              tempObject[key][arrayIndex] = {}
            } else if (!tempObject[key][arrayIndex]){
              tempObject[key][arrayIndex] = {}
            }
          } else {
            tempObject[key] = {}
          }
          if (arrayIndex !== -1) {
            tempObject = tempObject[key][arrayIndex]
          } else {
            tempObject = unflattenJson
          }
        })
      }
      return unflattenJson
    },
    /**
     * Whenever we apply filter for a field in issue, that field along with chosen value is maintained in an array and API call is made.
     * For changes on properties apart from first element of array, the filter is applied based on previous results fetched from API.
     * Whenever all the filtered values of first element of array is removed, then the corresponding entry is removed from array
     * And all the elements in array is shifted by one position. Subsequent API calls will be made, only on changes of new first element
     * @param {*} entity - array having filter name and corresponding field name in issue table. For form fields, this array will also include form field id as third element in array
     * @param {*} values - values filtered for selected field
     */
    applyFilter(entity, values) {
      if (entity && values) {
        if (!this.allFilters.length && values.length) {
          if(!this.allFilters.find(filter => filter.key[0] === entity[0])) {
            this.allFilters.push({ key: entity, values: values })
          }
        } else {
          const filterIndex = this.allFilters.findIndex(filter =>
            filter.key[0] === entity[0] &&
            filter.key[1] === entity[1]
          )
          if (filterIndex === -1 && values.length) {
            this.allFilters.push({ key: entity, values: values })
          } else {
            if (values.length) {
              this.allFilters.splice(filterIndex, 1, { key: entity, values: values })
            } else {
              this.allFilters = this.allFilters.filter(filter =>
                filter.key[0] !== entity[0] ||
                filter.key[1] !== entity[1]
              )
            }
          }
        }
      } else {
        this.allFilters = []
      }

      this.generateSearchCriteria(this.allFilters)
      this.localIssuesFiltersPreference = this.generateUnflattenJson()
    },
    handleSaveIssuesColumnsPreference(issuesColumnsPreference) {
      if (this.isIssuesPage) {
        this.updateUser({
          id                     : this.loggedInUser.id,
          issuesColumnsPreference: issuesColumnsPreference
        })
      } else {
        this.localIssuesColumnsPreference = issuesColumnsPreference
        this.showIssuesColumnsPreference  = false
      }
    },
    exportIssues(type) {
      this.addExport({
        type
      })
    },
    clearIssueSearchPollingInterval() {
      if (this.issueSearchPollingInterval) {
        clearInterval(this.issueSearchPollingInterval)
        this.issueSearchPollingInterval = null
      }
    },
    handleIssueSearch() {
      if (
        !this.isLoadingIssues
        && this.isSearchCriteriaChanged
        && this.isIssueSearchCompleted
      ) {
        if (this.isIssuesPage) {
          this.loadIssueSearchWithCriteria({
            criteria: {
              searchText               : this.searchText,
              issuesFilters            : this.localIssuesFiltersPreference,
              searchThroughAllIssueData: this.searchThroughAllIssueData
            }
          })
        } else {
          this.loadIssueSearchWithCriteria({
            filterId: this.currentFilter.id,
            criteria: {
              searchText               : this.searchText,
              issuesFilters            : this.localIssuesFiltersPreference,
              searchThroughAllIssueData: this.searchThroughAllIssueData
            },
            columnPreference: this.localIssuesColumnsPreference
          })
        }
      }
    },
    handleCancelAddIssue() {
      this.showAddIssueDialog      = false
      this.screenItemsForComponent = []
    },
    handleSubmitAddIssue(screenItems) {
      const localScreenItems = { ...screenItems }
      this.domainId          = screenItems.domainId
      const [transitionLink] = this.initialTransitionCase.transitionLinks
      if (!localScreenItems.issueForms) {
        localScreenItems.issueForms = []
        const issueForms
          = this.initialTransitionCase.screen?.screenItems?.filter(screenItem => screenItem.form)
        for (const issueForm of issueForms) {
          localScreenItems.issueForms.push({
            formTemplateId: issueForm.form.formTemplateId,
            formFields    : issueForm.form.formFields.map(({ formTemplateConfigurationId }) => ({
              formTemplateConfigurationId,
              value: null
            }))
          })
        }
      }
      this.makeTransition({
        id  : this.initialTransitionCase.id,
        data: {
          ...localScreenItems,
          typeId  : this.issueTypeCase.id,
          statusId: transitionLink.toStatusId
        }
      })
    },
    updateUserColumnsSortingPreference(updateOptions) {
      const sortBy             = this.loggedInUserIssuesColumnsSortingPreference.sortBy
      const sortDesc           = this.loggedInUserIssuesColumnsSortingPreference.sortDesc
      const hasSortByChanged   = JSON.stringify(sortBy) !== JSON.stringify(updateOptions.sortBy)
      const hasSortDescChanged = JSON.stringify(sortDesc) !== JSON.stringify(updateOptions.sortDesc)

      if (hasSortByChanged || hasSortDescChanged) {
        this.localIssuesSortingPreference = {
          sortBy  : updateOptions.sortBy,
          sortDesc: updateOptions.sortDesc
        }
      }
    },
    handleCancelBulkImportCases() {
      this.showBulkImportCasesDialog = false
    },
    handleBulkImportCases(dataToUpdated){
      this.showBulkImportCasesDialog = false
      this.uploadCsvFilesToBulkImport(dataToUpdated)
    },
    onIssueSearchBlur() {
      setTimeout(() => {
        if (!this.ignoreSearchFieldBlur) {
          this.handleIssueSearch()
        }
      }, 0) // Delay to let the checkbox click event occur first
    },
    onAdvancedSearchCheckboxClick() {
      this.ignoreSearchFieldBlur = true // Prevent handling search field blur for this click
      if (this.isSearchTextChanged || this.searchText) {
        this.handleIssueSearch()
      }
      setTimeout(() => {
        this.ignoreSearchFieldBlur = false
      }, 0) // Reset after checkbox click is processed
    },
    handleAddFilter(filter) {
      this.isSavingExistingFilterAsNew = filter.isSavingExistingFilterAsNew
      if (filter.isSavingExistingFilterAsNew) {
        this.addFilter({
          name            : filter.filterName,
          criteria        : this.localIssuesFiltersPreference,
          columnPreference: this.localIssuesColumnsPreference
        })
      } else {
        this.addFilter({
          name: filter.filterName
        })
      }
    },
    handleAdvancedFiltersUpdate(advancedFilters) {
      this.advancedFiltersCount = advancedFilters.length
    },
    toggleFilterActionsVisibility(value) {
      this.filterActionsVisible = value
    },
    handleResetFilter() {
      this.allFilters = []
    },
    handleUpdateFilter() {
      this.updateFilter({
        id              : this.currentFilter.id,
        criteria        : this.localIssuesFiltersPreference,
        columnPreference: this.localIssuesColumnsPreference
      })
    }
  },
  computed: {
    ...mapGetters({
      issues                                    : "issues/issues",
      lastSearchIssues                          : "issues/lastSearchIssues",
      isLoadingIssues                           : "issues/isLoadingIssues",
      domains                                   : "domains/domains",
      issueStatuses                             : "issueStatuses/issueStatuses",
      issueResolutions                          : "issueResolutions/issueResolutions",
      users                                     : "users/users",
      usersIncludingDeletedUsers                : "users/usersIncludingDeletedUsers",
      channels                                  : "channels/channels",
      labels                                    : "labels/labels",
      loggedInUserPolicies                      : "accessControl/loggedInUserPolicies",
      optionListItems                           : "optionListItems/optionListItems",
      fields                                    : "fields/fieldsV2",
      formTemplates                             : "formTemplates/formTemplates",
      formTemplateConfigurations                : "formTemplateConfigurations/formTemplateConfigurations",
      loggedInUser                              : "auth/loggedInUser",
      issueFields                               : "issueFields/issueFields",
      loggedInUserIssuesColumnsPreference       : "users/loggedInUserIssuesColumnsPreference",
      isUpdatingIssuesColumnsPreference         : "users/isUpdatingIssuesColumnsPreference",
      isIssuesColumnsPreferenceUpdated          : "users/isIssuesColumnsPreferenceUpdated",
      loggedInUserIssuesRowsPerPagePreference   : "users/loggedInUserIssuesRowsPerPagePreference",
      languages                                 : "languages/languages",
      isAddingExport                            : "exports/isAddingExport",
      latestIssuesExport                        : "exports/latestIssuesExport",
      loggedInUserIssuesFiltersPreference       : "users/loggedInUserIssuesFiltersPreference",
      isLoadingIssueSearch                      : "issueSearch/isLoadingIssueSearch",
      issueSearch                               : "issueSearch/issueSearch",
      isIssueFieldsEnabled                      : "configurations/isIssueFieldsEnabled",
      isExtendedSearchEnabled                   : "configurations/isExtendedSearchEnabled",
      isTransitionDone                          : "transitions/isTransitionDone",
      isTransitionInProgress                    : "transitions/isTransitionInProgress",
      workflows                                 : "workflows/workflows",
      types                                     : "issueTypes/issueTypes",
      accesses                                  : "accesses/accesses",
      groupOfUsers                              : "users/groupsOfUsers",
      isTasksEnabled                            : "configurations/isTasksEnabled",
      isReporterIntakeFormsEnabled              : "configurations/isReporterIntakeFormsEnabled",
      workflowAssociations                      : "workflowAssociations/workflowAssociations",
      resolutions                               : "issueResolutions/issueResolutions",
      dataRetentionPeriods                      : "dataRetentionPeriods/dataRetentionPeriods",
      lastCreatedIssue                          : "transitions/lastCreatedIssue",
      loggedInUserIssuesColumnsSortingPreference: "users/loggedInUserIssuesColumnsSortingPreference",
      isUpdatingIssuesColumnsSortingPreference  : "users/isUpdatingIssuesColumnsSortingPreference",
      isIssueFormsEnabled                       : "configurations/isIssueFormsEnabled",
      issueFormTemplates                        : "formTemplates/issueFormTemplates",
      isCsvFileUploadedForBulkImport            : "bulkImport/isCsvFileUploadedForBulkImport",
      isProgressBarInitiated                    : "shared/isProgressBarInitiated",
      allowedIssuesAndDomainsForIssueViewPolicy : "accessControl/allowedIssuesAndDomainsForIssueViewPolicy",
      isProgressBarPromisesPending              : "shared/isProgressBarPromisesPending",
      filterAddError                            : "filters/filterAddError",
      isAddingFilter                            : "filters/isAddingFilter",
      isFilterAdded                             : "filters/isFilterAdded",
      filters                                   : "filters/filters",
      issueFormTemplatesForIssueTypes           : "issueFormTemplates/issueFormTemplates",
      isUpdatingCriteria                        : "filters/isUpdatingCriteria",
      isCriteriaUpdated                         : "filters/isCriteriaUpdated",
      savedFilterUpdatePolicies                 : "accessControl/savedFilterUpdatePolicies",
      isSavedFiltersEnabled                     : "configurations/isSavedFiltersEnabled"
    }),
    isIssuesPage() {
      return this.$route.name === "issues"
    },
    sortedDomains() {
      return [...this.domains].sort((firstDomain, secondDomain) => firstDomain.name.localeCompare(secondDomain.name))
    },
    sortedLabels() {
      return [...this.labels].sort((firstLabel, secondLabel) => firstLabel.name.localeCompare(secondLabel.name))
    },
    sortedUsers() {
      return [...this.users].sort((firstUser, secondUser) => firstUser.name.localeCompare(secondUser.name))
    },
    activeDomains() {
      return this.domains.filter(domain => domain.archived === false).map(domain => {
        return {
          text             : domain.name,
          value            : domain.id,
          hasAccessToDomain: this.userHasAccessToTheDomain(domain.id)
        }
      })
    },
    activeDomainsToDisplay(){
      return this.activeDomains
    },
    sortedIssueIds() {
      return this.issues.map(issue => issue.id)
        .sort((firstIssueId, secondIssueId) => firstIssueId - secondIssueId)
    },
    resolution() {
      return this.resolutions.find(resolution => resolution.id === this.issue?.resolutionId)
    },
    resolutionsToDisplay() {
      return this.resolutions.map(resolution => {
        return {
          value: resolution.id,
          text : resolution.name
        }
      })
    },
    labelsForSelection() {
      const labelsForSelection = [{
        header: this.$t("604")
      }]
      labelsForSelection.push(...this.labels.map(label => label.name))
      return labelsForSelection
    },
    usersWithUnassignedOption() {
      const usersWithDetails = []
      for (const user of this.users) {
        if (user?.enabled && user.id !== this.loggedInUser.id) {
          usersWithDetails.push(user)
        }
      }
      return [{
        ...this.loggedInUser,
        name: this.$t("951")
      }, ...usersWithDetails]
    },
    retentionPeriods() {
      if (this.dataRetentionPeriods.length) {
        return [...this.dataRetentionPeriods].sort((firstDataRetentionPeriod, secondRetentionPeriod) =>
          firstDataRetentionPeriod.value - secondRetentionPeriod.value)
          .map(dataRetentionPeriod => dataRetentionPeriod.value)
      }
      return DEFAULT_DATA_RETENTION_PERIODS
    },
    retentionPeriodsToDisplay() {
      const dataRetentionPeriods = []
      for (const dataRetentionOption of this.retentionPeriods) {
        const dataRetentionPeriod = convertDaysToDuration(dataRetentionOption)
        dataRetentionPeriods.push({
          value: this.$moment().add(dataRetentionOption, "days").format("YYYY-MM-DD"),
          text : this.$tc(dataRetentionPeriod.localeValue, dataRetentionPeriod.count,
            { count: dataRetentionPeriod.count })
        })
      }
      return dataRetentionPeriods
    },
    loggedInUserGroupIds() {
      return this.groupOfUsers[this.loggedInUser.id]?.map(group => group.id)
    },
    caseWorkflowAssociation() {
      return this.workflowAssociations.find(association => association.issueTypeId === this.issueTypeCase.id)
    },
    caseWorkflow() {
      return this.workflows.find(workflow => workflow.id === this.caseWorkflowAssociation.workflowId)
    },
    initialTransitionCase() {
      return this.caseWorkflow.transitions.find(transition => transition.initialTransition)
    },
    issueTypeCase() {
      return this.types.find(type => type.id === ISSUE_TYPES[0].id)
    },
    latestIssuesExportStatus() {
      return this.latestIssuesExport?.status
    },
    isIssueFieldsExportInProgress() {
      return this.isAddingExport || this.isIssuesExportInitiated
    },
    isIssuesExportInitiated() {
      return this.latestIssuesExportStatus === DATA_EXPORT_STATUS.INITIATED
    },
    issueResolutionsMap() {
      const issueResolutionsMap = new Object()
      for (const issueResolution of this.issueResolutions) {
        issueResolutionsMap[issueResolution.id] = issueResolution
      }
      return issueResolutionsMap
    },
    issueStatusesMap() {
      const issueStatusesMap = new Object()
      for (const issueStatus of this.issueStatuses) {
        issueStatusesMap[issueStatus.id] = issueStatus
      }
      return issueStatusesMap
    },
    issueFieldMap() {
      const issueFieldMap = new Object()
      for (const issueField of this.issueFields) {
        issueFieldMap[issueField.id] = issueField
      }
      return issueFieldMap
    },
    usersMap() {
      const usersMap = new Object()
      for (const user of this.usersIncludingDeletedUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    issuesForTable: function() {
      const result = this.lastSearchIssues.map(issue => {
        const user        = this.usersMap[issue.assigneeId]
        const fieldValues = {}

        const formatFieldValue = (field, value) => {
          switch (field.type) {
            case FIELD_TYPES.DATE.value:
              return value ? this.$moment(value).format("DD MMMM YYYY") : undefined
            case FIELD_TYPES.DATE_TIME.value:
              return value ? this.$moment(value).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined
            case FIELD_TYPES.BOOLEAN.value:
              return value === "true" ? this.$t("1048") : this.$t("1049")
            case FIELD_TYPES.MULTIPLE_OPTION_LIST.value:
              return value ? value.join(", ") : []
            default:
              return value
          }
        }

        if (issue.fields && Object.keys(issue.fields).length) {
          for (const [issueFieldId, issueFieldValue] of Object.entries(issue.fields)) {
            const issueField = this.issueFieldMap[issueFieldId]
            if (issueField) {
              const field = this.fieldsMap[issueField.fieldId]
              if (field) {
                fieldValues[`field-${issueFieldId}`] = formatFieldValue(field, issueFieldValue)
              }
            }
          }
        }

        if (issue.forms && Object.keys(issue.forms).length) {
          for (const [configurationId, fieldValue] of Object.entries(issue.forms)) {
            const configuration = this.formTemplateConfigurationsMap[configurationId]
            if (configuration) {
              const field = this.fieldsMap[configuration.fieldId]
              if (field) {
                fieldValues[`form-${configurationId}`] = formatFieldValue(field, fieldValue)
              }
            }
          }
        }

        return {
          id               : issue.id,
          lastUpdated      : this.$moment(issue.updatedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)"),
          domain           : this.domains.find(domain => domain.id === issue.domainId)?.name,
          status           : this.issueStatusesMap[issue.statusId]?.name,
          assignee         : user?.name,
          enabled          : user?.enabled,
          labels           : issue.labels,
          closedOn         : issue.closedAt ? this.$moment(issue.closedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          acknowledgedOn   : issue.acknowledgedAt ? this.$moment(issue.acknowledgedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          receivedOn       : issue.receivedAt ? this.$moment(issue.receivedAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          resolution       : this.issueResolutionsMap[issue.resolutionId]?.name,
          summary          : issue.summary,
          createdOn        : this.$moment(issue.createdAt).format("DD MMMM YYYY HH:mm:ss (UTCZ)"),
          deletedAt        : user?.deletedAt,
          type             : issue.typeId,
          parentId         : issue.parentId,
          dataRetainedUntil: issue.dataRetainedUntil ? this.$moment(issue.dataRetainedUntil).format("DD MMMM YYYY") : undefined,
          dueDate          : issue.dueDate ? this.$moment(issue.dueDate).format("DD MMMM YYYY HH:mm:ss (UTCZ)") : undefined,
          ...fieldValues
        }
      })

      return result
    },
    headersForTable() {
      const iconHeader      = this.$TABLES.ISSUES.headers[0]
      const idHeader        = this.$TABLES.ISSUES.headers[1]
      const headersForTable = [{
        ...iconHeader
      }, {
        ...idHeader,
        text: this.$t(idHeader.text)
      }]
      for (const issuesColumnPreference of this.issuesColumnsPreference) {
        if (issuesColumnPreference.selected) {
          if (issuesColumnPreference.type === "issue-property") {
            const header = this.$TABLES.ISSUES.headers.find(item => item.value === issuesColumnPreference.column)
            headersForTable.push({
              ...header,
              text: this.$t(header.text)
            })
          } else {
            let field, displayName, columnId
            if (issuesColumnPreference.type === "issue-field") {
              const issueField = this.issueFields.find(issueField => issueField.id === issuesColumnPreference.column)
              if (issueField) {
                field       = this.fieldsMap[issueField.fieldId]
                displayName = field.systemName
                columnId    = `field-${issueField.id}`
              }
            } else if (issuesColumnPreference.type === "form-field") {
              const configurationId = issuesColumnPreference.column
              const configuration   = this.formTemplateConfigurationsMap[configurationId]
              if (configuration) {
                const formTemplateId = configuration.formTemplateId
                const formName       = this.formTemplatesMap[formTemplateId]?.name
                const fieldId        = configuration.fieldId
                field                = this.fieldsMap[fieldId]
                displayName          = this.$t("2254", { fieldName: field?.systemName, formName })
                columnId             = `form-${configurationId}`
              }
            }

            if (field) {
              switch (field.type) {
                case FIELD_TYPES.DATE.value:
                  headersForTable.push(this.$TABLES.ISSUES.dateColumn(displayName, columnId))
                  break
                case FIELD_TYPES.DATE_TIME.value:
                  headersForTable.push(this.$TABLES.ISSUES.dateTimeColumn(displayName, columnId))
                  break
                case FIELD_TYPES.BOOLEAN.value:
                  headersForTable.push(this.$TABLES.ISSUES.booleanColumn(displayName, columnId))
                  break
                case FIELD_TYPES.MULTIPLE_OPTION_LIST.value:
                  headersForTable.push(this.$TABLES.ISSUES.textColumn(displayName, columnId, false))
                  break
                default:
                  headersForTable.push(this.$TABLES.ISSUES.textColumn(displayName, columnId))
                  break
              }
            }
          }
        }
      }
      return headersForTable
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.ISSUES, this.$t.bind(this))
    },
    defaultSortByColumn() {
      return this.localIssuesSortingPreference.sortBy
    },
    defaultSortDesc() {
      return this.localIssuesSortingPreference.sortDesc
    },
    fieldsMap() {
      const fieldsMap = {}
      for  (const field of this.fields) {
        fieldsMap[field.id] = field
      }
      return fieldsMap
    },
    formTemplatesMap() {
      const formTemplatesMap = {}
      for  (const formTemplate of this.formTemplates) {
        formTemplatesMap[formTemplate.id] = formTemplate
      }
      return formTemplatesMap
    },
    hasAccessToViewIssueSearch(){
      return this.loggedInUserPolicies["IssueSearch view"]
    },
    canAddIssue() {
      return !!this.loggedInUserPolicies["Issue add"]
    },
    canImportIssues() {
      return !!this.loggedInUserPolicies["BulkImport add"]
    },
    canExportIssues() {
      const exportAddPolicies = this.loggedInUserPolicies["Export add"]
      if (exportAddPolicies) {
        for (const exportAddPolicy of exportAddPolicies) {
          if (exportAddPolicy.set.type.includes("issues with selected fields")) {
            return true
          }
        }
      }
      return false
    },
    canAddFilter() {
      return !!this.loggedInUserPolicies["SavedFilter add"] && this.isSavedFiltersEnabled
    },
    canUpdateFilter() {
      const currentFilterUpdatePolicy = this.savedFilterUpdatePolicies.find(filterUpdatePolicy =>
        filterUpdatePolicy.id === +this.$route.params?.filterId
      )
      return currentFilterUpdatePolicy?.set?.criteria !== undefined
    },
    canViewIssueForms() {
      return true
    },
    isUserIssuesFilterPreferenceChanged() {
      if (!this.isIssuesPage) {
        return false
      }
      return JSON.stringify(this.loggedInUserIssuesFiltersPreference) !==
      JSON.stringify(this.localIssuesFiltersPreference)
    },
    isUserIssuesSortingPreferenceChanged() {
      return JSON.stringify(this.loggedInUserIssuesColumnsSortingPreference) !==
      JSON.stringify(this.localIssuesSortingPreference)
    },
    latestIssueSearchStatus() {
      return this.issueSearch?.status
    },
    isIssueSearchInitiated() {
      return this.latestIssueSearchStatus === ISSUE_SEARCH_STATUS.INITIATED
    },
    isIssueSearchCompleted() {
      return this.latestIssueSearchStatus === ISSUE_SEARCH_STATUS.COMPLETED
    },
    isSearchTextChanged() {
      return this.issueSearch?.criteria && this.searchText !== this.issueSearch.criteria.searchText
    },
    isSearchThroughAllIssueDataChanged() {
      return this.issueSearch?.criteria
      && this.searchThroughAllIssueData !== this.issueSearch?.criteria?.searchThroughAllIssueData
    },
    isIssueSearchCriteriaForFilterChanged() {
      return !this.isIssuesPage &&
      !checkObjectEqualityIgnoreOrder(this.issueSearch?.criteria?.issuesFilters, this.localIssuesFiltersPreference)
    },
    isFilterCriteriaChanged() {
      return !this.isIssuesPage &&
      !checkObjectEqualityIgnoreOrder(this.currentFilter.criteria, this.localIssuesFiltersPreference)
    },
    isSearchCriteriaChanged() {
      return this.isUserIssuesFilterPreferenceChanged
      || this.isSearchTextChanged
      || this.isSearchThroughAllIssueDataChanged
    },
    shouldPollForIssueSearch() {
      return this.isIssueSearchInitiated && !this.isLoadingIssueSearch && !this.issueSearchPollingInterval
    },
    currentFilter() {
      return this.filters.find(filter => filter.id === +this.$route.params?.filterId)
    },
    filterPreference() {
      return this.isIssuesPage ? this.loggedInUserIssuesFiltersPreference : this.currentFilter.criteria
    },
    issuesColumnsPreference() {
      return this.isIssuesPage ? this.loggedInUserIssuesColumnsPreference :
        this.localIssuesColumnsPreference ?? this.currentFilter.columnPreference
    },
    advancedFilters() {
      const advancedFilters = [{
        header: this.$t("1246")
      }, {
        name: this.$t("2066"),
        key : this.$t("2066")
      }, {
        name: this.$t("2069"),
        key : this.$t("2069")
      }, {
        name: this.$t("227"),
        key : this.$t("227")
      }, {
        name: this.$t("226"),
        key : this.$t("226")
      }, {
        name: this.$t("292"),
        key : this.$t("292")
      }, {
        name: this.$t("291"),
        key : this.$t("291")
      }, {
        name: this.$t("1003"),
        key : this.$t("1003")
      }, {
        name: this.$t("694"),
        key : this.$t("694")
      }, {
        name: this.$t("224"),
        key : this.$t("224")
      }, {
        name: this.$t("1838"),
        key : this.$t("1838")
      }, {
        name: this.$t("2129"),
        key : this.$t("2129")
      }]

      if (this.issueFields && this.isIssueFieldsEnabled) {
        const filteredIssueFields = this.issueFields
          .filter(issueField => [
            FIELD_TYPES.OPTION_LIST.value,
            FIELD_TYPES.MULTIPLE_OPTION_LIST.value,
            FIELD_TYPES.BOOLEAN.value
          ]
            .includes(this.fieldsMap[issueField.fieldId]?.type)
          )
          .map(issueField => this.fieldsMap[issueField.fieldId])

        const uniqueFilteredIssueFields = [...new Set(filteredIssueFields)]

        advancedFilters.push({
          header: this.$t("2250")
        })

        uniqueFilteredIssueFields.forEach(field =>
          advancedFilters.push({
            name: field.systemName,
            key : `issue-fields-${field.id}`
          })
        )
      }

      advancedFilters.push(...[{
        header: this.$t("1247")
      }, {
        name: this.$t("285"),
        key : this.$t("285")
      }, {
        name: this.$t("1161"),
        key : this.$t("1161")
      }, {
        name: this.$t("631"),
        key : this.$t("631")
      }, {
        name: this.$t("1160"),
        key : this.$t("1160")
      }])

      if (this.formTemplateConfigurations.length && this.isReporterIntakeFormsEnabled
        && this.reporterFormsAndFields.length
      ) {
        for (const reporterForm of this.reporterFormsAndFields) {
          if (reporterForm.formFields.length) {
            advancedFilters.push({
              header: reporterForm.formName
            })
          }
          reporterForm.formFields.forEach(field => {
            advancedFilters.push({
              name          : this.$t("2254", { fieldName: field.systemName, formName: reporterForm.formName }),
              key           : `reporter-intake-forms-${field.id}`,
              fieldId       : field.id,
              value         : `reporter-intake-forms-${field.id}-${reporterForm.formTemplateId}`,
              formTemplateId: reporterForm.formTemplateId,
              formName      : reporterForm.formName
            })
          })
        }
      }

      if (this.issueFormTemplatesForIssueTypes.length && this.isIssueFormsEnabled
        && this.issueFormsAndFields.length
      ) {
        advancedFilters.push(...[{
          header: this.$t("2381")
        }])
        for (const issueForm of this.issueFormsAndFields) {
          if (issueForm.formFields.length) {
            advancedFilters.push({
              header: issueForm.formName
            })
          }
          issueForm.formFields.forEach(field => {
            advancedFilters.push({
              name          : this.$t("2254", { fieldName: field.systemName, formName: issueForm.formName }),
              key           : `issue-forms-${field.id}`,
              fieldId       : field.id,
              value         : `issue-forms-${field.id}-${issueForm.formTemplateId}`,
              formTemplateId: issueForm.formTemplateId,
              formName      : issueForm.formName
            })
          })
        }
      }
      return advancedFilters
    },
    reporterFormsAndFields() {
      const formsAndFields = []
      for (const formTemplate of this.formTemplates.filter(formTemplate => formTemplate.reportForm)) {
        if (formTemplate) {
          const configurations = this.formTemplateConfigurations
            .filter(config => config.formTemplateId === formTemplate.id)
          const formFields     = []
          for (const configuration of configurations) {
            const field = this.fields.find(field=> field.id === configuration.fieldId)
            if (field) {
              if ([FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.BOOLEAN.value].includes(field.type)) {
                formFields.push(field)
              }
            }
          }
          formsAndFields.push({
            formTemplateId: formTemplate.id,
            formName      : formTemplate.name,
            formFields
          })
        }
      }
      return formsAndFields
    },
    issueFormsAndFields() {
      const formsAndFields = []
      for (const issueFormTemplate of this.issueFormTemplatesForIssueTypes) {
        const formTemplate = this.formTemplates.find(formTemplate =>
          formTemplate.id === issueFormTemplate.formTemplateId
        )
        if (formTemplate) {
          const configurations = this.formTemplateConfigurations
            .filter(config => config.formTemplateId === issueFormTemplate.formTemplateId)
          const formFields     = []
          for (const configuration of configurations) {
            const field = this.fields.find(field => field.id === configuration.fieldId)
            if (field) {
              if ([
                FIELD_TYPES.OPTION_LIST.value,
                FIELD_TYPES.BOOLEAN.value,
                FIELD_TYPES.MULTIPLE_OPTION_LIST.value
              ].includes(field.type)) {
                formFields.push(field)
              }
            }
          }
          formsAndFields.push({
            formTemplateId: formTemplate.id,
            formName      : formTemplate.name,
            formFields
          })
        }
      }
      return formsAndFields
    },
    getActiveDomains() {
      return this.domains.filter(domain => !domain.archived)
    },
    caseStatusesMap( ) {
      const caseStatusesMap = new Object()
      for (const issueStatus of this.caseWorkflow.statuses) {
        caseStatusesMap[issueStatus.id] = issueStatus
      }
      return caseStatusesMap
    },
    showSkeletonLoader() {
      return this.isProgressBarPromisesPending || this.isLoadingIssues || this.isIssueSearchInitiated
    },
    calculateHeightForIssuesTable() {
      const issuesCount = Math.min(this.issuesForTable.length, this.loggedInUserIssuesRowsPerPagePreference)

      let heightOfTable
      const dataRowHeight      = 63
      const minimumTableHeight = this.tableHeaderHeight + dataRowHeight

      let filterElementsTotalHeight = 0
      if (this.advancedFiltersCount) {
        let numberOfFilterElements = this.advancedFiltersCount
        if (this.filterActionsVisible) {
          numberOfFilterElements += 1
        }
        const advancedFilterRows  = Math.ceil(numberOfFilterElements / 4)
        filterElementsTotalHeight = advancedFilterRows * 64
      }

      let otherElementsHeight = 263
      if (!this.isIssuesPage) {
        otherElementsHeight += 57
      }
      const extraHeaderHeight  = this.tableHeaderHeight - 48
      const maxAvailableHeight =
      window.innerHeight - otherElementsHeight - filterElementsTotalHeight - extraHeaderHeight
      const maxDataRows        = (maxAvailableHeight / dataRowHeight) - 1

      if (issuesCount > maxDataRows) {
        heightOfTable = this.tableHeaderHeight + (maxDataRows * dataRowHeight)
      } else if (!issuesCount) {
        heightOfTable = minimumTableHeight
      } else {
        heightOfTable = this.tableHeaderHeight + (issuesCount * dataRowHeight)
      }

      if (heightOfTable < minimumTableHeight) {
        heightOfTable = minimumTableHeight
      }

      return heightOfTable
    },
    latestFilterAdded() {
      return this.filters[this.filters.length - 1]
    },
    hasIssueSearchAccess() {
      return !!this.loggedInUserPolicies["IssueSearch view"]
    },
    formTemplateConfigurationsMap() {
      const formTemplateConfigurationsMap = new Object()
      for (const formTemplateConfiguration of this.formTemplateConfigurations) {
        formTemplateConfigurationsMap[formTemplateConfiguration.id] = formTemplateConfiguration
      }
      return formTemplateConfigurationsMap
    },
    issueFormTemplateIds() {
      return this.issueFormTemplatesForIssueTypes.map(issueFormTemplate => issueFormTemplate.formTemplateId)
    },
    issueFieldIds() {
      return this.issueFields.map(issueField => issueField.fieldId)
    },
    getFormTemplateConfigurationSortingMap() {
      return this.formTemplateConfigurations.reduce((acc, formTemplateConfiguration) => {
        acc[formTemplateConfiguration.id] = formTemplateConfiguration.sortingOrder
        return acc
      }, {})
    }
  },
  watch: {
    currentFilter: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.localIssuesFiltersPreference = newValue.criteria
          this.localIssuesColumnsPreference = newValue.columnPreference
        }
      }
    },
    isTasksEnabled: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.issueTypes.push({
            header: this.$t("1273")
          },
          {
            name : ISSUE_TYPES[1].name,
            value: ISSUE_TYPES[1].id
          })
        }
      }
    },
    isUserIssuesFilterPreferenceChanged: {
      deep   : true,
      handler: function(newValue) {
        if (newValue) {
          if (!this.isLoadingIssues && this.isIssueSearchCompleted) {
            this.loadIssueSearchWithCriteria({
              criteria: {
                searchText               : this.searchText,
                issuesFilters            : this.localIssuesFiltersPreference,
                searchThroughAllIssueData: this.searchThroughAllIssueData
              }
            })
          }
        }
      }
    },
    isIssueSearchCriteriaForFilterChanged: {
      deep   : true,
      handler: function(newValue) {
        if (newValue) {
          if (!this.isLoadingIssues && this.isIssueSearchCompleted) {
            this.loadIssueSearchWithCriteria({
              filterId: this.currentFilter.id,
              criteria: {
                searchText               : this.searchText,
                issuesFilters            : this.localIssuesFiltersPreference,
                searchThroughAllIssueData: this.searchThroughAllIssueData
              },
              columnPreference: this.localIssuesColumnsPreference
            })
          }
        }
      }
    },
    loggedInUserIssuesColumnsSortingPreference: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && !this.localIssuesSortingPreference) {
          this.localIssuesSortingPreference = newValue
        }
      }
    },
    isUserIssuesSortingPreferenceChanged: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && !this.isUpdatingIssuesColumnsSortingPreference) {
          this.updateUser({
            id                            : this.loggedInUser.id,
            issuesColumnsSortingPreference: this.localIssuesSortingPreference
          })
        }
      }
    },
    isIssuesColumnsPreferenceUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.showIssuesColumnsPreference = false
          this.loadIssuesFromIssueSearchId({ issueSearchId: this.issueSearch.id })
        }
      }
    },
    shouldPollForIssueSearch: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.issueSearchPollingInterval = setInterval(() => {
            this.loadIssueSearch({ id: this.issueSearch.id })
          }, ISSUE_SEARCH_POLL_INTERVAL)
        }
      }
    },
    latestIssueSearchStatus: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue === ISSUE_SEARCH_STATUS.COMPLETED || newValue === ISSUE_SEARCH_STATUS.FAILED) {
          this.clearIssueSearchPollingInterval()
          this.loadIssuesFromIssueSearchId({ issueSearchId: this.issueSearch.id })
        }
      }
    },
    isLoadingIssues: {
      immediate: true,
      handler  : function(newValue) {
        if (
          !newValue
          && this.isIssueSearchCompleted
          && this.isSearchCriteriaChanged
          && !this.isLoadingIssueSearch
          && this.isIssuesPage
        ) {
          this.loadIssueSearchWithCriteria({
            criteria: {
              searchText               : this.searchText,
              issuesFilters            : this.localIssuesFiltersPreference,
              searchThroughAllIssueData: this.searchThroughAllIssueData
            }
          })
        }
      }
    },
    isTransitionDone: {
      handler: function(newValue) {
        if (newValue) {
          this.showAddIssueDialog = false
          if (this.domainId && this.userHasAccessToTheDomain(this.domainId) && this.hasAccessToViewIssueSearch) {
            this.$router.push({ name: "issue", params: { id: this.lastCreatedIssue } })
            this.domainId = null
          }
        }
      }
    },
    isCsvFileUploadedForBulkImport: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "1873"
          })
        }
      }
    },
    lastSearchIssues: {
      handler: function(newValue) {
        if (newValue) {
          this.setProgressBarPromisesPending(false)
        }
      }
    },
    "$route.query": {
      immediate: true,
      handler  : function(newValue) {
        const { unanswered, unread, dueSoon, overdue, inactive, status, assigneeId } = newValue || {}
        if (unanswered || unread || dueSoon || overdue || inactive || status || assigneeId) {
          this.$router.replace({
            query: {}
          })
        }
      }
    },
    isFilterAdded: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2082"
          })
          if (this.isSavingExistingFilterAsNew) {
            this.setPageTitle(PAGE_TITLE_WITHOUT_TRANSLATION(this.latestFilterAdded.name))
            this.setBreadcrumbs(FILTER(
              { params: { filterId: this.latestFilterAdded.id } }, this.latestFilterAdded))
          }
          this.$router.push({ name: "filter", params: { filterId: this.latestFilterAdded.id } })
          this.localIssuesFiltersPreference = {}
          this.localIssuesColumnsPreference = this.latestFilterAdded.columnPreference
        }
      }
    },
    isCriteriaUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.notify({
            type: "success",
            text: "2154"
          })
        }
      }
    },
    "$route.name": {
      immediate: true,
      handler  : async function() {
        this.showIssueFilters = false
        await this.$nextTick()
        this.showIssueFilters = true
      }
    },
    showSkeletonLoader: {
      immediate: true,
      handler  : async function(newValue) {
        if (!newValue) {
          this.$nextTick(() => {
            const table  = this.$refs.table_issues.$el
            const header = table.querySelector("thead")
            if (header) {
              this.tableHeaderHeight = header.offsetHeight
            }
          })
        }
      }
    }
  }
}